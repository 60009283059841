import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import ls from 'local-storage'
import { removeFromArray, numberToMoney } from './helpers'
import GatsbyLink from '../GatsbyLink'
import { FaTrash } from 'react-icons/fa';
import './Quote.scss'
import { Image } from '../Image'
import RenderContent from '../RenderContent'
import { navigate } from "gatsby"

class QuoteList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quote: ls.get('quote') ? ls.get('quote') : null ,
      loaded: false,
      error: null
    }
  }
  
  removeThis = ( item ) => {
    console.log('item:',item)
    var quote = removeFromArray(this.state.quote, item);


    ls.set('quote', quote);

    this.setState({
      quote
    });
  }
  render() {
    const { quote } = this.state
    const { data, location } = this.props
    console.log('location:',location)
    const { products, wordpressAcfOptions } = data
    const { showPrice, showQuantity, pricePrefix, priceSuffix, priceDescription, cartPage = '/request-a-quote/' } = wordpressAcfOptions.options
    let quoteTatal = 0
    //console.log('products:',products)
    //console.log('quote:',quote)
    return (
      <div className="quote-list">
        <table>
          <tbody>
          <tr>
            <th colSpan="2">Product Name</th>
            {showQuantity && <th className="qty">Qty</th>}
            {showPrice && <th className="price">Price</th>}
            <th className="note">Note</th>
            <th>Action</th>
          </tr>
          {quote && quote.map((item, index) => {
            const thisProduct = products.edges.filter(i => i.node.wordpress_id === item.productId)
            quoteTatal = quoteTatal + (parseInt(thisProduct[0].node.acf.productPrice) * parseInt(item.qty))
            console.log('thisProduct:',thisProduct)
            return (
              <tr key={index}>
                <td className="image"><div className="thumb"><Image src={thisProduct[0].node.featured_image_url}/></div></td>
                <td className="title">
                  <GatsbyLink title="View product" to={thisProduct[0].node.link}>{thisProduct[0].node.title}</GatsbyLink>
                  <RenderContent content={thisProduct[0].node.excerpt} />
                </td>
                {showQuantity && <td className="qty">{item.qty}</td>}
                {showPrice && <td className="price">{numberToMoney(parseInt(thisProduct[0].node.acf.productPrice) * parseInt(item.qty))}</td>}
                <td className="note">{item.note}</td>
                <td><button onClick={() => this.removeThis(item)} className="remove" title="Remove"><FaTrash/></button></td>
              </tr>
            )
          })}
          </tbody>
        </table>
        <div className="quote-end">
          <div><GatsbyLink className="action" to="/products/">Add to your quote</GatsbyLink></div>
          {showPrice && <div className="totals">
            <div><h3><strong>Quote total estimate:</strong> {numberToMoney(quoteTatal)}</h3></div>
            <div><p>This is an indication of price. Please submit the quote for price confirmation and to place your order</p></div>
          </div>}
        </div>
      </div>
    )
  }
}



export const Quote = (props) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          wordpressAcfOptions {
            options {
              showPrice
              showQuantity
              pricePrefix
              priceSuffix
              priceDescription
              quote
              quotePage
            }
          },
          products: allWordpressWpProducts {
            edges {
              node {
                ...ProductListFields
              }
            }
          }
        }
      `}
      render={data => <QuoteList {...props} data={data} />}
    />
  );
}
